import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'




const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)
const getBfirePriceBBKFI = async () => {

      const calls = [
        // Bfire Balance
        {
          address: '0xE5599A12a8fF06A690ffD2790C54A37c74A43208',
          name: 'balanceOf',
          params: ['0xeDD2C9aDd0493E4dc0Aad7C36650cb1F00AD8B73'],
        },
        // BBKfi balance
        {
          address: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
          name: 'balanceOf',
          params: ['0xeDD2C9aDd0493E4dc0Aad7C36650cb1F00AD8B73'],
        },
      ]

      const [
        BFIREBalanceBFIREBBKFILP,
        BBKFIBalanceBFIREBBKFILP,

      ] = await multicall(erc20, calls)

      const BfirePriceBBKFI = BBKFIBalanceBFIREBBKFILP / BFIREBalanceBFIREBBKFILP
      setData(BfirePriceBBKFI)
      

}
getBfirePriceBBKFI()
return data
}

export default useGetPriceData
