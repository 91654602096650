import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import App from './App'
import Providers from './Providers'


const Certik = styled.div`
.audit-container.visible {
  opacity: 1;
  pointer-events: auto;
}
.audit-container {
  position: fixed;
  display: flex;
  height: 50px;
  bottom: 25px;
  right: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 2px solid #1e4f59;
  -webkit-box-align: center;
  align-items: center;
  padding: 13px 25px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
  pointer-events: none;

}
.square {
  max-width: 140%;
  max-height: 140%;
}
.mini-tag {
  font-family: 'Comic Sans MS', 'Comic Sans MS';
  position: relative;
  display: block;
  font-size: 11px;
  font-weight: 700;
  background-color: #1e4f59;
  border-radius: 10px;
  border: 1px solid #1e4f59;
  padding: 2px 4px;
  color: rgb(255, 255, 255);
  box-shadow: #3e80ca 0px 0px 15px;
}
.audit-container.large > .txt {
  margin-right: 10px;
  color: #1e4f59;
  font-size: 14px;
  white-space: nowrap;
}
.txt {
  position: relative;
  font-size: 14px;
  font-family: 'Comic Sans MS', 'Comic Sans MS';
}
.audit-container.large > .pending {
  position: absolute;
  left: -15px;
  top: -15px;
}

.aBwtF {
  background-image: url(../images/sunflowersbackground1.png);
  background-position: center bottom;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: repeat-x;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  padding-bottom: 420px;
  background-size: 1500px;
}

`

ReactDOM.render(
  <React.StrictMode>
    <Providers>
    <Certik>
        <a className="audit-container large visible" href="https://www.certik.org/projects/bitblocksfinance" target="_blank" rel="noreferrer">
          <span className="txt">Audited by</span>
          <img className="square" src="../images/certik-logo.png" alt="logo"/>
              <div className="mini-tag pending">COMPLETED</div>
              </a>
      </Certik>

      <App />
      <Certik><div className="aBwtF"> </div></Certik>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
