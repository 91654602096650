import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [  

    {
        sousId: 5,
        tokenName: 'WBBK',
        rewardTokenAddress: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
        isRewardLp: false,
        stakingTokenName: QuoteToken.WBBK,
        stakingtokenpricepid: 27,
        stakingTokenAddress: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
        isStakingLp: false,
        contractAddress: {
          97: '',
          56: '0x3088541abcd80033ffe18abe12523dc79a2eca6d',
        },
        poolCategory: PoolCategory.CORE,
        projectLink: 'https://bitblocksproject.com/',
        router: 'BBK',
        harvest: true,
        tokenPerBlock: '0.046',
        sortOrder: 999,
        isFinished: false,
        tokenDecimals: 18,
        DepositFee: 0,
        pricepid: 27,
      },
      {
        sousId: 6,
        tokenName: 'WBBK',
        rewardTokenAddress: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
        isRewardLp: false,
        stakingTokenName: QuoteToken.LPWBBKBNB,
        stakingtokenpricepid: 27,
        stakingTokenAddress: '0x9d2934ee00322a2c0adb447f9a0dfef5fdd5142d',
        isStakingLp: true,
        stakingLpIndexToken: 'BNB',
        contractAddress: {
          97: '',
          56: '0x720066fb24df4ca2ad08c2ff71d1ae00d1bded68',
        },
        poolCategory: PoolCategory.CORE,
        projectLink: 'https://bitblocksproject.com/',
        router: 'BBK',
        harvest: true,
        tokenPerBlock: '0.046',
        sortOrder: 999,
        isFinished: false,
        tokenDecimals: 18,
        DepositFee: 0,
        pricepid: 27,
      },
    
  

]

export default pools
