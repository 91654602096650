import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: "https://bitblocks.finance",
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: "Exchange",
        href: "https://exchange.bitblocks.finance//#/swap",
      },
      {
        label: "Liquidity",
        href: "https://exchange.bitblocks.finance//#/pool",
      },
      {
        label: "Exchange (Cake Router v2)",
        href: "https://pancakerouterv2.bitblocks.finance//#/swap",
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://bitblocks.finance/farms',
    items: [
      {
        label: "BBKFI Farms",
        href: "https://bitblocks.finance/farms",
      },
      {
        label: "Community Farms",
        href: "https://bitblocks.finance/bbkfi-houses",
      },

    ]
  },
  {
    label: 'Vote',
    icon: 'GroupsIcon',
    href: 'https://snapshot.org/#/bitblocks.eth',
  },
  {
    label: 'Buy BBKFI',
    icon: 'IfoIcon',
    href: 'https://exchange.bitblocks.finance//#/swap?inputCurrency=BNB&outputCurrency=0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
  },
  {
    label: 'Roadmap',
    icon: 'RoadmapIcon',
    href: 'https://docs.bitblocksproject.com/projects/bitblocks-finance/roadmap',
  },
  {
    label: 'Tools',
    icon: 'NftIcon',
    items: [
      {
        label: 'Lp Worth calculator',
        href: 'https://lp-calc.web.app/',
      },
      {
        label: 'Portifolio Tracker (PACOCA)',
        href: 'https://pacoca.io/',
      },
      {
        label: 'Farm stats',
        href: 'https://bitblocksfinance.web.app/farm.html',
      },
      {
        label: '-- BitBlocks Bridge -- BBK <-> wBBK',
        href: 'https://bitblocksproject.com/BRIDGE/?',
      },

    ],
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.bitblocksproject.com/projects/bitblocks-finance',
      },
      {
        label: 'Github',
        href: 'https://github.com/BitBlocksProject',
      },
      {
        label: 'BitBlocksFinance Info',
        href: 'https://info.bitblocks.finance',
      },
      {
        label: 'PancakeSwap Info',
        href: 'https://pancakeswap.info/token/0xfC8E68E30350c6603D3d29fCc8E676380C28FCf4',
      },

    ],
  },
  {
    label: 'Audited by Certik',
    icon: 'AuditIcon',
    href: 'https://www.certik.org/projects/bitblocksfinance',
  },
  {
    label: 'BitBlocks Website',
    icon: 'MoreIcon',
    href: 'https://bitblocksproject.com/',
  },
]

export default config
