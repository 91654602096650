import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd , usePriceBfireBusd, usePriceWbbkBusd } from 'state/hooks'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const bfirePriceUsd = usePriceBfireBusd()
  const wbbkPriceUsd = usePriceWbbkBusd()
  const Dtoken = 0

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      Dtoken={Dtoken}
      cakePriceUsd={cakePriceUsd.toNumber()}
      DtokenPriceUsd={bfirePriceUsd.toNumber()}
      wbbkPriceUsd={wbbkPriceUsd.toNumber()}
      links={config}
      priceLink="https://dexscreener.com/bsc/0x504c6425b2a0fa1e43da37364d1a2ddfc2e049de"
      priceLinkDtoken="https://dexscreener.com/bsc/0xec972af7689c5d06d3a8c3c0ff8cb1528bef8860"
      priceLinkWBBK="https://dexscreener.com/bsc/0xf385f92c0372a8653b6697e7d6380127da848631"
      {...props}
    />
  )
}

export default Menu
