import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [


  {
    pid: 63,
    risk: 5,
    lpSymbol: 'BBKFI-BNB',
    lpAddresses: {
      97: '',
      56: '0x504c6425b2a0fa1e43da37364d1a2ddfc2e049de',
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 64,
    risk: 5,
    lpSymbol: 'BBKFI-BUSD',
    lpAddresses: {
      97: '',
      56: '0xdfC232C39BCB6dba5Cc96f382aDf8618847fec61',
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 65,
    risk: 5,
    lpSymbol: 'WBBK-BNB',
    lpAddresses: {
      97: '',
      56: '0x9D2934EE00322A2c0ADb447f9a0DfeF5fdD5142D',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 66,
    risk: 5,
    lpSymbol: 'WBBK-BUSD',
    lpAddresses: {
      97: '',
      56: '0x4d548a8fE07752Ef70115477062a63CA70F4c51E',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 67,
    risk: 5,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7197a1326d627c975959a8b5a51478749D09bD32',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 31,
    risk: 5,
    lpSymbol: 'BBKFI-BNB',
    lpAddresses: {
      97: '',
      56: '0xCfd8010223dFa66e80669c803533312C7C102605',
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 0,
    risk: 5,
    lpSymbol: 'BBKFI-BUSD',
    lpAddresses: {
      97: '',
      56: '0x6154a783E73Ac4A9C8fcb015C36BcE6CE1Ee6D1e',
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 30,
    risk: 5,
    lpSymbol: 'BBKFI-BUSD',
    lpAddresses: {
      97: '',
      56: '0xeE56e52923B4d4d15cfE14F281BbA471416C534d',
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'BBKFI-BNB',
    lpAddresses: {
      97: '',
      56: '0x87188D72cB8c2C2D275958Ff6417dd89b87691E1',
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfc8e68e30350c6603d3d29fcc8e676380c28fcf4',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 18,
    risk: 5,
    lpSymbol: 'WBBK-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xf475131322f3Ae8028F5dD1eC618d56590D3A681',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 29,
    risk: 5,
    lpSymbol: 'WBBK-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xF385F92C0372A8653b6697e7d6380127Da848631',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 62,
    risk: 5,
    lpSymbol: 'BFIRE-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xeDD2C9aDd0493E4dc0Aad7C36650cb1F00AD8B73',
    },
    tokenSymbol: 'BFIRE',
    tokenAddresses: {
      97: '',
      56: '0xe5599a12a8ff06a690ffd2790c54a37c74a43208',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 61,
    risk: 5,
    lpSymbol: 'BFIRE-BNB',
    lpAddresses: {
      97: '',
      56: '0xec972af7689c5d06d3a8c3c0ff8cb1528bef8860',
    },
    tokenSymbol: 'BFIRE',
    tokenAddresses: {
      97: '',
      56: '0xEC972af7689C5d06d3A8C3c0Ff8Cb1528bEF8860',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'WBBK-BUSD',
    lpAddresses: {
      97: '',
      56: '0x9AA308a70122Bc6453054E83285ed0dea507e967',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 28,
    risk: 5,
    lpSymbol: 'WBBK-BUSD',
    lpAddresses: {
      97: '',
      56: '0x398b497EF5d1994dE8FC3eb2271d042aA095359F',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'WBBK-BNB',
    lpAddresses: {
      97: '',
      56: '0x51d810d76e46c5acd47f5d22f3dbcc1951eecdc0',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 27,
    risk: 5,
    lpSymbol: 'WBBK-BNB',
    lpAddresses: {
      97: '',
      56: '0x9f12Cd74Fc23FDD98269d7f26474b9DD3b00A488',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 33,
    risk: 5,
    lpSymbol: 'CAKE-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x1856aAb417688d387BFcB8C4F9E2345e9F005977',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 34,
    risk: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 58,
    risk: 5,
    lpSymbol: 'BTCB-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x5A35d6B4C828C627B900427fF03c14CA485AF8A5',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },

  {
    pid: 59,
    risk: 5,
    lpSymbol: 'ETH-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x1b056fda83a4aA769de0BC3A00ff97D7d269A5Db',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 40,
    risk: 5,
    lpSymbol: 'ADA-BUSD',
    lpAddresses: {
      97: '',
      56: '0x1E249DF2F58cBef7EAc2b0EE35964ED8311D5623',
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '',
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 41,
    risk: 5,
    lpSymbol: 'ADA-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xF9E985843518A19BbBF3F4c29588ba88E6866Ddb',
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '',
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },

  {
    pid: 56,
    risk: 5,
    lpSymbol: 'AXS-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xE869856385D0cb3EeCe7779fc7Ef7Aa70D8997E0',
    },
    tokenSymbol: 'AXS',
    tokenAddresses: {
      97: '',
      56: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 57,
    risk: 5,
    lpSymbol: 'AXS-BNB',
    lpAddresses: {
      97: '',
      56: '0xC2d00De94795e60FB76Bc37d899170996cBdA436',
    },
    tokenSymbol: 'AXS',
    tokenAddresses: {
      97: '',
      56: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 38,
    risk: 5,
    lpSymbol: 'DOGE-BNB',
    lpAddresses: {
      97: '',
      56: '0xac109C8025F272414fd9e2faA805a583708A017f',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 39,
    risk: 5,
    lpSymbol: 'DOGE-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xA7c5bbfB54eCD667bC91553fecAb9eF42c71B871',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 4,
    risk: 2,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 44,
    risk: 2,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 5,
    risk: 2,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 45,
    risk: 2,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'DAI-BUSD',
    lpAddresses: {
      97: '',
      56: '0x3aB77e40340AB084c3e23Be8e5A6f7afed9D41DC',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 46,
    risk: 5,
    lpSymbol: 'DAI-BUSD',
    lpAddresses: {
      97: '',
      56: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '',
      56: '0x680dd100e4b394bda26a59dd5c119a391e747d18',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 47,
    risk: 5,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 36,
    risk: 5,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 52,
    risk: 5,
    lpSymbol: 'CBRL-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xC9954E86b9BA5307a0A9E099aD5BDa2F8Be6dC42',
    },
    tokenSymbol: 'CBRL',
    tokenAddresses: {
      97: '',
      56: '0x9e691fd624410d631c082202b050694233031cb7',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 53,
    risk: 5,
    lpSymbol: 'CBRL-WBBK',
    lpAddresses: {
      97: '',
      56: '0x628a4d25123994151b23087d516887571686Aa8B',
    },
    tokenSymbol: 'CBRL',
    tokenAddresses: {
      97: '',
      56: '0x9e691fd624410d631c082202b050694233031cb7',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.WBBK,
    quoteTokenAdresses: contracts.wbbk,
  },

  {
    pid: 14,
    risk: 5,
    lpSymbol: 'WLQX-BNB',
    lpAddresses: {
      97: '',
      56: '0x96c3Bef501F5ddC82e0717839Dba5F4e1F75429d',
    },
    tokenSymbol: 'WLQX',
    tokenAddresses: {
      97: '',
      56: '0x4495e673eE53c61CE79c35D5e299733639362aEc',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 48,
    risk: 5,
    lpSymbol: 'WLQX-BNB',
    lpAddresses: {
      97: '',
      56: '0x91649446095135aD5e0B597Fe8069f0922Ce5C7e',
    },
    tokenSymbol: 'WLQX',
    tokenAddresses: {
      97: '',
      56: '0x4495e673eE53c61CE79c35D5e299733639362aEc',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 15,
    risk: 5,
    lpSymbol: 'WLQX-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x6d66A8c919663AcdFBC7975294185295ADCf7aE5',
    },
    tokenSymbol: 'WLQX',
    tokenAddresses: {
      97: '',
      56: '0x4495e673eE53c61CE79c35D5e299733639362aEc',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 26,
    risk: 5,
    lpSymbol: 'WLQX-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x7D220fD92D59ab32B5Bc1533685213B2cD4f2F93',
    },
    tokenSymbol: 'WLQX',
    tokenAddresses: {
      97: '',
      56: '0x4495e673eE53c61CE79c35D5e299733639362aEc',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 42,
    risk: 5,
    lpSymbol: 'WXDN-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xcd0064D130F91B6E5D0c0eCCd4E67A70c09BDa99',
    },
    tokenSymbol: 'WXDN',
    tokenAddresses: {
      97: '',
      56: '0xbea2576f400b070c7cdf11d1cbb49de0c84e3bcf',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 43,
    risk: 5,
    lpSymbol: 'WXDN-BNB',
    lpAddresses: {
      97: '',
      56: '0xCFA07A86B3016DA7A1b07c0cbd3d8227184441e9',
    },
    tokenSymbol: 'WXDN',
    tokenAddresses: {
      97: '',
      56: '0xbea2576f400b070c7cdf11d1cbb49de0c84e3bcf',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 54,
    risk: 5,
    lpSymbol: 'ZAIF-BNB',
    lpAddresses: {
      97: '',
      56: '0x32C065Da7daFe3CFF90a5fE41FEC1e6D71473Cf8',
    },
    tokenSymbol: 'ZAIF',
    tokenAddresses: {
      97: '',
      56: '0x280C3Fc949b1a1D7a470067cA6F7b48b3CB219c5',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 55,
    risk: 5,
    lpSymbol: 'ZAIF-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x33C5fa31b1324f5979797891266c64F99CFe2724',
    },
    tokenSymbol: 'ZAIF',
    tokenAddresses: {
      97: '',
      56: '0x280C3Fc949b1a1D7a470067cA6F7b48b3CB219c5',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 21,
    risk: 5,
    lpSymbol: 'SAFEMOON-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x877ad4722f32FC7e0f3F07769EF5FdDD11Cc3993',
    },
    tokenSymbol: 'SAFEMOON',
    tokenAddresses: {
      97: '',
      56: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 22,
    risk: 5,
    lpSymbol: 'SAFEMOON-BNB',
    lpAddresses: {
      97: '',
      56: '0x9adc6Fb78CEFA07E13E9294F150C1E8C1Dd566c0',
    },
    tokenSymbol: 'SAFEMOON',
    tokenAddresses: {
      97: '',
      56: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 23,
    risk: 5,
    lpSymbol: 'SAFEMOON-BNB',
    lpAddresses: {
      97: '',
      56: '0xfF3dD404aFbA451328de089424C74685bf0a43C9',
    },
    tokenSymbol: 'SAFEMOON',
    tokenAddresses: {
      97: '',
      56: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 24,
    risk: 5,
    lpSymbol: 'SAFEMOON-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x81D3b62B7E90fe05d90a63Cb114e29E43d4EE6Ab',
    },
    tokenSymbol: 'SAFEMOON',
    tokenAddresses: {
      97: '',
      56: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },

  {
    pid: 16,
    risk: 5,
    lpSymbol: 'REAU-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xf654CBCfE6dF2F6311C048BbAb16a5790BA43997',
    },
    tokenSymbol: 'REAU',
    tokenAddresses: {
      97: '',
      56: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 25,
    risk: 5,
    lpSymbol: 'REAU-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x670cf57518D747329968a279Ca3Ab7345cBF7222',
    },
    tokenSymbol: 'REAU',
    tokenAddresses: {
      97: '',
      56: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 17,
    risk: 5,
    lpSymbol: 'REAU-BNB',
    lpAddresses: {
      97: '',
      56: '0x7Cc956136C36e7Fbd6B74C07d9E40Eccd3779249',
    },
    tokenSymbol: 'REAU',
    tokenAddresses: {
      97: '',
      56: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 37,
    risk: 5,
    lpSymbol: 'REAU-BNB',
    lpAddresses: {
      97: '',
      56: '0xB7f1E7227607DEf335c3051f0Cb394DEe8CcFbB8',
    },
    tokenSymbol: 'REAU',
    tokenAddresses: {
      97: '',
      56: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 60,
    risk: 5,
    lpSymbol: 'SHIBANINJA-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x0eF233072De3C2Be96220a66059900c8c6b28cAa',
    },
    tokenSymbol: 'SHIBANINJA',
    tokenAddresses: {
      97: '',
      56: '0x2652449c059400485ff91bede15bec9e62889288',
    },
    router: "cakev2",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },

  {
    pid: 68,
    risk: 5,
    lpSymbol: 'ETH-BBKFI',
    lpAddresses: {
      97: '',
      56: '0x0f19f7E30EABEb2581AAcb40350968dad4AeC30D',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },

  {
    pid: 69,
    risk: 5,
    lpSymbol: 'WBBK-BBKFI',
    lpAddresses: {
      97: '',
      56: '0xD5EfB52b631413Dd47A2b33699e31Aff4304FD21',
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
  {
    pid: 70,
    risk: 5,
    lpSymbol: 'BTCB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x2BCFEd8a944b6CC2713ED39DE69345e98b991CA1',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 71,
    risk: 5,
    lpSymbol: 'ETH-BUSD',
    lpAddresses: {
      97: '',
      56: '0xF5b4CD81c6e7829c756aBE7D5Ad9E4fB3648852b',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 72,
    risk: 5,
    lpSymbol: 'ETH-BTCB',
    lpAddresses: {
      97: '',
      56: '0xe4C9E401Eb1EB389Bcf98F8B8BD70e50ea8c814a',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    router: "BitBlocks",
    quoteTokenSymbol: QuoteToken.BTCB,
    quoteTokenAdresses: contracts.btcb,
  },
  {
    pid: 9,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BBKFI',
    lpAddresses: {
      97: '',
      56: '0x6154a783E73Ac4A9C8fcb015C36BcE6CE1Ee6D1e', // BBKFI-BUSD LP
    },
    tokenSymbol: 'BBKFI',
    tokenAddresses: {
      97: '',
      56: '0xfC8E68E30350c6603D3d29fCc8E676380C28FCf4',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 20,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WBBK',
    lpAddresses: {
      97: '',
      56: '0x9AA308a70122Bc6453054E83285ed0dea507e967', // WBBK-BUSD LP
    },
    tokenSymbol: 'WBBK',
    tokenAddresses: {
      97: '',
      56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 32,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6', // CAKE-WBNB LP
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 10,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0x7561EEe90e24F3b348E1087A005F78B4c8453524', // BTCB-BNB LP
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422', // ETH-BNB LP
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc', // DAI-BUSD LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // WBNB-BUSD LP
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 49,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ADA',
    lpAddresses: {
      97: '',
      56: '0x1E249DF2F58cBef7EAc2b0EE35964ED8311D5623', // ADA-BUSD LP
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '',
      56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 51,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'CBRL',
    lpAddresses: {
      97: '',
      56: '0xC9954E86b9BA5307a0A9E099aD5BDa2F8Be6dC42', // CBRL-BBKFI LP
    },
    tokenSymbol: 'CBRL',
    tokenAddresses: {
      97: '',
      56: '0x9e691fd624410d631c082202b050694233031cb7',
    },
    router: "none",
    quoteTokenSymbol: QuoteToken.BBKFI,
    quoteTokenAdresses: contracts.bbkfi,
  },
]

export default farms
